<template>
  <a-row :gutter="[12, 12]">
    <a-col :span="24" style="width: 256px">
      <a-input v-model="searchForm.search" placeholder="名称, 备注" allowClear @pressEnter="search" />
    </a-col>
    <a-col :span="24" style="width: 256px">
      <a-select v-model="searchForm.status" placeholder="状态" allowClear style="width: 100%" @change="search">
        <a-select-option key="pending" value="pending">等待审核</a-select-option>
        <a-select-option key="agree" value="agree">同意</a-select-option>
        <a-select-option key="refuse" value="refuse">拒绝</a-select-option>
      </a-select>
    </a-col>
    <a-col :span="24" style="width: 256px">
      <a-select v-model="searchForm.system" placeholder="系统" allowClear style="width: 100%" @change="search">
        <a-select-option key="test_wms" value="test_wms">WMS 测试环境</a-select-option>
        <a-select-option key="test_erp" value="test_erp">ERP 测试环境</a-select-option>
      </a-select>
    </a-col>
    <a-col :span="24" style="width: 100px">
      <a-button type="primary" icon="search" :loading="dataLoading" @click="search" style="width: 100%">查询</a-button>
    </a-col>

    <a-col :span="24">
      <a-table
        rowKey="id"
        size="small"
        :columns="columns"
        :dataSource="dataItems"
        :loading="dataLoading"
        :pagination="pagination"
        @change="changeTable"
      >
        <template slot="action" slot-scope="value, item">
          <table-action :item="item" @update="update" @destroy="destroy" />
        </template>
      </a-table>
    </a-col>
  </a-row>
</template>

<script>
import { insertItem, replaceItem, removeItem } from "@/utils/functions";
import { reviewList } from "@/apis/data";

export default {
  components: {
    TableAction: () => import("./TableAction"),
  },
  data() {
    return {
      searchForm: { search: "", page: 1, ordering: undefined, status: "pending" },
      pagination: { current: 1, total: 0, pageSize: 16 },
      dataLoading: false,

      // Table
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "状态",
          dataIndex: "status",
          customRender: (_, item) => item.status_display,
        },
        {
          title: "公司",
          dataIndex: "company",
        },
        {
          title: "用户名",
          dataIndex: "username",
        },
        {
          title: "续费周期",
          dataIndex: "renewal_period",
        },
        {
          title: "到期日期",
          dataIndex: "expiry_date",
        },
        {
          title: "申请系统",
          dataIndex: "system_display",
        },
        {
          title: "账号数量",
          dataIndex: "user_count",
        },
        {
          title: "申请人",
          dataIndex: "creator",
          customRender: (_, item) => item.creator_item.name,
        },
        {
          title: "申请时间",
          dataIndex: "create_time",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 120,
          scopedSlots: { customRender: "action" },
        },
      ],
      dataItems: [],

      createModalVisible: false,
    };
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    list() {
      this.dataLoading = true;
      reviewList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    create(item) {
      this.dataItems = insertItem(this.dataItems, item);
    },
    update(item) {
      this.dataItems = replaceItem(this.dataItems, item);
    },
    destroy(item) {
      this.dataItems = removeItem(this.dataItems, item);
    },
    changeTable(pagination, _filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
